import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
const Home = lazy(() => import("../pages/Home"));
const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/*",
    element: <Navigate to="/" />,
  },
];

export default routes;
